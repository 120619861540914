<template>
  <v-card flat>
    <v-card-text>
      <h3 class="red--text">
        Importe los contactos que participarán en la votación
      </h3>
      <p>Descargar <a @click.prevent="downloadTemplate">plantilla</a></p>
      <v-row>
        <v-col cols="12" md="9">
          <v-file-input
            prepend-icon=""
            label="Seleccione el archivo"
            :error-messages="this.errors['file']"
            v-model="file"
            :disabled="!canEdit"
          ></v-file-input>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn
            color="grey darken-3"
            block
            dark
            @click="submit"
            :disabled="!canEdit"
          >
            <v-icon>mdi-import</v-icon>Cargar archivo
          </v-btn>
        </v-col>
      </v-row>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Filtrar"
        single-line
        hide-details
      ></v-text-field>
      <v-data-table
        :headers="headers"
        :items="voters"
        :server-items-length="totalVoters"
        :loading="loading"
        fixed-header
        height="50vh"
        mobile-breakpoint="200"
        :options.sync="options"
        :footer-props="{
          itemsPerPageOptions: [10, 20, 30]
        }"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.voter.identification">
              <td>{{ item.voter.identification }}</td>
              <td>{{ item.voter.name }}</td>
              <td>{{ item.voter.email }}</td>
              <td>{{ item.apt ? "Sí" : "No" }}</td>
              <td>{{ item.factor }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="red"
        class="mx-auto mt-5"
        width="40%"
        rounded
        dark
        :to="getRoute('configuration')"
      >
        <v-icon>mdi-arrow-left-bold</v-icon>Atrás
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="red"
        class="mx-auto mt-5"
        width="40%"
        rounded
        dark
        :to="getRoute('notification')"
      >
        Siguiente<v-icon>mdi-arrow-right-bold</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import elections from "@/services/elections";
import electionCanEdit from "@/mixins/electionCanEdit";

export default {
  name: "BallotLoadData",
  mixins: [electionCanEdit],
  props: ["election"],
  data: () => ({
    file: null,
    errors: {},
    voters: [],
    totalVoters: 0,
    loading: true,
    search: "",
    options: {},
    headers: [
      { text: "Identificación", value: "voter_identification" },
      { text: "Nombre", value: "voter_name" },
      { text: "Correo", value: "voter_email" },
      { text: "Hábil", value: "apt" },
      { text: "Factor", value: "factor" }
    ]
  }),
  watch: {
    election() {
      if (this.election.id) {
        this.getVoters();
      }
    },
    search() {
      if (this.election.id) {
        this.getVoters();
      }
    },
    options: {
      handler() {
        if (this.election.id) {
          this.getVoters();
        }
      },
      deep: true
    }
  },
  methods: {
    submit() {
      let self = this;
      let formData = new FormData();
      formData.append("file", this.file);
      elections
        .uploadVoters(this.election, formData)
        .then(() => {
          self.errors = {};
          this.options = {
            sortBy: [],
            sortDesc: [],
            page: 1,
            itemsPerPage: 10
          };
          self.getVoters();
        })
        .catch(error => {
          if (error.response) {
            self.errors = error.response.data;
          }
        });
    },
    getVoters() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let sort = null;
      sort = sortBy[0] + (sortDesc[0] ? " desc" : " asc");
      let opts = {
        sorts: sort,
        page: page,
        per_page: itemsPerPage,
        q: `voter_identification_or_voter_name_or_voter_email_cont: ${this.search}`
      };
      elections.getVoters(this.election, opts).then(
        response => {
          this.voters = response.data.election_voters;
          this.totalVoters = response.data.pagination.total;
          this.loading = false;
        },
        error => {
          console.log(error);
        }
      );
    },
    downloadTemplate() {
      elections.downloadVotersTemplate(this.election).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "tuvoto_online_plantilla.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    getRoute(option) {
      return "/wizard/" + this.election.id + "/" + option;
    }
  }
};
</script>